<template>
  <div>
    <i class="bi bi-check2"></i>
  </div>
</template>

<script>
import 'bootstrap-icons/font/bootstrap-icons.css';
export default {
};
</script>

<style>
.bi-check2 {
    color: rgb(177, 168, 168);
    width: 10px;
    height: 50px;
}
.bi-check2:hover {
    color:white;
}
</style>
